// This is a file to support all non-migrate es6 files
// like backend.js
// Here should be added files that are imported in non-compiled
// es6 files

import './public-path';
import LazyHammer from './libs/Hammer';
window.LazyHammer = LazyHammer;
import 'static/js/album.js';
import './social.js';
import './moebius.js';
import './components/lazy/lazy';
import './utils/fullScreenVideo/utils';
import '../../../Habitissimo/Utils/Web/Src/Component/components';
import { isInView } from './utils/isInView';
window.isInView = isInView;
